import React from "react";
import styled from "styled-components";
import { H1, H2, H3 } from "../../../theme/Styles";
import CaptionedImage from "../../ui/captionedImage/CaptionedImage";
import {
  ContentSection,
  HeroImage,
  ProjectContainer,
  ProjectP,
  SummerySection,
} from "../styles";
import { WizzData } from "./WizzData";

import decibleScenarioImage from "./assets/scenario.jpg";
import initialBriefImage from "./assets/initial_brief.svg";
import papersImage from "./assets/papers.jpg";

import barriersImage from "./assets/barriers.svg";
import redefinedBriefImage from "./assets/redefined_brief.svg";
import quotesImage from "./assets/quotes.svg";
import frameworkImage from "./assets/framework.svg";

import industryImage from "./assets/industry.jpg";
import industry2Image from "./assets/industry2.jpg";

import elementsImage from "./assets/elements.png";
import spaceImage from "./assets/space.svg";

import visualsImage from "./assets/visuals.jpg";
import refinedvisualsImage from "./assets/refinedvisuals.jpg";
import digitalSketchingImage from "./assets/digital.jpg";

import lowfiImage from "./assets/lowfi.jpg";
import midfiImage from "./assets/midfi.jpg";
import iconImage from "./assets/icon.jpg";
import paperImage from "./assets/paper.jpg";
import mockupImage from "./assets/mockup.jpg";

import teamImage from "./assets/team.svg";

import FullwidthImage from "../../ui/fullwidthImage/FullwidthImage";
import TopBar from "../../ui/topBar/TopBar";
import { devices } from "../../../theme/devices";

const Title = styled(H1)`
  width: 55vw;
  @media ${devices.mobile} {
    width: 70vw;
  }
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  margin: auto;

  iframe {
    position: absolute;
    top: 0;
    left: 10%;
    width: 80%;
    height: 90%;
  }
`;

export default function Wizz(props: {}) {
  return (
    <ProjectContainer>
      <TopBar></TopBar>
      <HeroImage src={WizzData.heroImage}></HeroImage>
      <SummerySection>
        <Title>{WizzData.title}</Title>
        <ProjectP>
          For more than 90% of Sri Lankans, English is not their first language.
          Yet, it is a vital skill to progress in many careers. Across the
          country, there are significant barriers to access affordable and
          effective English learning. I worked with a young ed-tech startup and
          led a multidisciplinary team of designers and english teachers to
          reimagine the learning and teaching of career-focused English.
        </ProjectP>

        <H3>My Role</H3>
        <ProjectP>
          I led the overall design process from refining the initial design
          brief to user research to prototyping. At the end, I developed a
          functional prototype of the system (a mobile app and web portal) for
          further testing and securing funds for next stages.
        </ProjectP>

        <H3>Duration</H3>
        <ProjectP>8 months (Feb 2020 - Oct 2020)</ProjectP>
      </SummerySection>
      <ContentSection>
        <H2>Context and Starting Brief</H2>
        <ProjectP>
          A young Sri Lankan startup which entered the English e-learning market
          a few years ago wanted to rethink the user experience of their
          existing mobile app as they were failing to retain users and
          completion rates were very low. During my initial engagements with
          them, I uncovered several issues in their lesson framework, app design
          and the overall business strategy. In response, I assembled a
          multidisciplinary team of designers, researchers and teachers and set
          out to rethink their business from the ground up.
        </ProjectP>
      </ContentSection>
      <CaptionedImage
        caption={""}
        imageSrc={initialBriefImage}
      ></CaptionedImage>

      <CaptionedImage caption={""} imageSrc={teamImage}></CaptionedImage>
      <ContentSection>
        <H2>Conducting preliminary research around the initial brief</H2>
        <ProjectP>
          We started by exploring the motivations of the Sri Lankans for
          learning English and the barriers they are facing. We reviewed a large
          body of previous research findings and conducted rapid qualitative
          interviews with 6 participants selected through convenience sampling.
        </ProjectP>
      </ContentSection>
      <CaptionedImage caption={""} imageSrc={papersImage}></CaptionedImage>

      <CaptionedImage caption={""} imageSrc={barriersImage}></CaptionedImage>
      <CaptionedImage caption={""} imageSrc={quotesImage}></CaptionedImage>

      <ContentSection>
        <H2>Reframing the problem</H2>
        <ProjectP>
          We uncovered that peoples’ motivations to learn English significantly
          relates to their aspirations to excel in their careers, from finding a
          good job to getting promoted frequently. With this insight, we
          redefined the brief to focus on building a learning platform that
          helps people to learn career-focused English skills.
        </ProjectP>
      </ContentSection>
      <CaptionedImage
        caption={""}
        imageSrc={redefinedBriefImage}
      ></CaptionedImage>
      <ContentSection>
        <H2>Leading the multidisciplinary design exploration</H2>
        <ProjectP>
          Now with the new design brief, I created a framework to add some
          structure to our design exploration. With my team, I defined a set of
          subproblems and selected the best possible methods to explore them,
          while allowing space for impromptu, unstructured creative work as
          well.
        </ProjectP>
      </ContentSection>
      <FullwidthImage imageSrc={frameworkImage}></FullwidthImage>
      <CaptionedImage caption={""} imageSrc={industryImage}></CaptionedImage>
      <CaptionedImage caption={""} imageSrc={industry2Image}></CaptionedImage>
      <CaptionedImage caption={""} imageSrc={elementsImage}></CaptionedImage>
      <CaptionedImage caption={""} imageSrc={spaceImage}></CaptionedImage>
      <CaptionedImage caption={""} imageSrc={visualsImage}></CaptionedImage>
      <CaptionedImage
        caption={""}
        imageSrc={digitalSketchingImage}
      ></CaptionedImage>
      {/* <FullwidthImage imageSrc={refinedvisualsImage}></FullwidthImage> */}
      <ContentSection>
        <H2>Putting everything together into a mobile app</H2>
        <ProjectP>
          Our next challenge was to translate the outcomes of our design
          exploration into a mobile app based learning experience. So we
          conducted multiple wireframe design and paper prototyping sessions and
          iteratively developed them into a refined mobile app design.
        </ProjectP>
      </ContentSection>
      <CaptionedImage caption={""} imageSrc={lowfiImage}></CaptionedImage>
      <CaptionedImage caption={""} imageSrc={paperImage}></CaptionedImage>
      <CaptionedImage caption={""} imageSrc={iconImage}></CaptionedImage>
      <CaptionedImage caption={""} imageSrc={midfiImage}></CaptionedImage>
      <FullwidthImage imageSrc={mockupImage}></FullwidthImage>

      <ContentSection>
        <H2>Developing a functional prototype of the app</H2>
        <ProjectP>
          Then I developed a functional app prototype with a selected set of
          features using React Native and Spring/Java. The goal of this
          prototype was to demonstrate the new app design and lesson strategy
          during investor presentations.
        </ProjectP>
      </ContentSection>
      <VideoContainer>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/h6GLVU2cKWQ"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </VideoContainer>

      <SummerySection>
        {/* <H2>Reflections</H2> */}
        <H3>What is the most important lesson I learned from this project?</H3>
        <ProjectP>
          Most of my team members did not have prior experience in human
          centered design. I learnt how to lead people from non-design
          backgrounds in the HCD process.
        </ProjectP>

        <H3>What was the most challenging?</H3>
        <ProjectP>
          Leading and managing a team remotely during COVID-19 and especially
          while preparing for my PhD defense.
        </ProjectP>

        <H3>What would I do differently if I were to do this project again?</H3>
        <ProjectP>
          I would plan out remote design sessions more thoughtfully. I would
          love to try out Figma Jam.{" "}
        </ProjectP>
      </SummerySection>
    </ProjectContainer>
  );
}
